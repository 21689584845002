<template>
  <section id="BrandingSignage">
    <!-- <ExperiencesCarousel class="desktop" :carouselImages="carouselImages" />
    <ExperiencesMobile class="mobile" />-->
    <div class="menu-underlay"></div>
    <b-carousel id="sectionCarousel" :interval="0" indicators controls>
      <b-carousel-slide>
        <template v-slot:img>
          <div
            class="carouselBg"
            :style="{
              backgroundImage:
                'url(' +
                require(`@/assets/images/Carousel/BrandingSignage/${bsImg1}`) +
                ')',
            }"
          >
            <div class="carouselImgWrapper">
              <img
                :src="
                  require(`@/assets/images/Carousel/BrandingSignage/${bsImg1}`)
                "
                class="carouselImg"
              />
              <div class="clientLogoWrapper slide1-01" v-if="clientLogo">
                <img :src="clientLogo" class="clientLogo" />
              </div>
              <div class="clientLogoWrapper slide1-02" v-if="clientLogo">
                <img :src="clientLogo" class="clientLogo" />
              </div>
            </div>
          </div>
          <div class="overlay"></div>
          <div class="caption">
            <h1>
              North Video Boards
              <div class="ruler"></div>
            </h1>
          </div>
        </template>
      </b-carousel-slide>

      <!-- <b-carousel-slide>
        <template v-slot:img>
          <div
            class="carouselBg"
            :style="{
              backgroundImage:
                'url(' +
                require(`@/assets/images/Carousel/BrandingSignage/${bsImg2}`) +
                ')',
            }"
          >
            <div class="carouselImgWrapper">
              <img
                :src="
                  require(`@/assets/images/Carousel/BrandingSignage/${bsImg2}`)
                "
                class="carouselImg"
              />
              <div class="clientLogoWrapper slide2" v-if="clientLogo">
                <img :src="clientLogo" class="clientLogo" />
              </div>
            </div>
          </div>
          <div class="overlay"></div>
          <div class="caption">
            <h1>
              Media Mesh
              <div class="ruler"></div>
            </h1>
          </div>
        </template>
      </b-carousel-slide> -->

      <b-carousel-slide>
        <template v-slot:img>
          <div
            class="carouselBg"
            :style="{
              backgroundImage:
                'url(' +
                require(`@/assets/images/Carousel/BrandingSignage/${bsImg3}`) +
                ')',
            }"
          >
            <div class="carouselImgWrapper">
              <img
                :src="
                  require(`@/assets/images/Carousel/BrandingSignage/${bsImg3}`)
                "
                class="carouselImg"
              />
              <div class="clientLogoWrapper slide3" v-if="clientLogo">
                <img :src="clientLogo" class="clientLogo" />
              </div>
            </div>
          </div>
          <div class="overlay"></div>
          <div class="caption">
            <h1>
              South Video Board
              <div class="ruler"></div>
            </h1>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </section>
</template>

<script>
export default {
  name: "BrandingSignage",
  data() {
    return {
      clientLogo: null,
      bsImg1: "bs-1-generic.jpg",
      bsImg2: "bs-2-generic.jpg",
      bsImg3: "bs-3-generic.jpg",
    };
  },
  mounted() {
    let pages = this.$store.state.epitch.ePitch.pages;
    pages.forEach((page) => {
      if (page.id === "116") {
        if (typeof page.image !== "undefined") {
          this.bsImg1 = "bs-1.jpg";
          this.bsImg2 = "bs-2.jpg";
          this.bsImg3 = "bs-3.jpg";
          this.clientLogo = `https://salesdeck.allegiantstadium.com/upload/${page.image}`;
        }
      }
    });
  },
};
</script>

<style lang="scss" scoped>
#BrandingSignage {
  .menu-underlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 268px;
    height: 100%;
    background-image: url("~@/assets/images/Carousel/generic-gallery-side-bg.jpg");
    box-shadow: 8px 0 8px 0 rgba(0, 0, 0, 0.5);
    z-index: 2;
    @media (max-width: 993px) {
      display: none;
    }
  }
  #sectionCarousel {
    .carousel-item {
      .carouselBg {
        min-height: 100vh;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        max-width: calc(100vw - 268px);
        margin-left: 268px;
        position: relative;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba(0, 0, 0, 0.85);
        }
        .carouselImgWrapper {
          position: absolute;
          z-index: 2;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          max-width: 1200px;
          box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.87);
        }
        .clientLogoWrapper {
          position: absolute;
          padding: 5px 10px;

          &.slide1-01 {
            width: 15%;
            height: 9%;
            bottom: 61%;
            left: 3%;
            transform: rotate(10deg);
            img {
              width: auto;
              height: 100%;
              margin: auto;
            }
          }
          &.slide1-02 {
            width: 9.3%;
            height: 7%;
            bottom: 56%;
            left: 45.2%;
            transform: rotate(-3deg);
            img {
              width: auto;
              height: 100%;
              margin: auto;
            }
          }

          &.slide2 {
            width: 22%;
            height: 16%;
            bottom: 29%;
            left: 38%;
            img {
              width: auto;
              height: 100%;
              margin: auto;
            }
          }

          &.slide3 {
            width: 14%;
            height: 15%;
            top: 8%;
            left: 31.5%;
            img {
              width: auto;
              height: 100%;
              margin: auto;
              object-fit: contain;
            }
          }
        }
      }
      .caption {
        position: absolute;
        bottom: 47px;
        left: 317px;
        display: flex;
        flex-direction: column;
        z-index: 2;
        @media (max-width: 993px) {
          left: 50%;
          transform: translate(-50%);
          bottom: -180px;
        }

        h1 {
          font-family: "ArtegraSans-Bold";
          font-size: 35px;
          color: #ffffff;
          letter-spacing: 2.19px;
          line-height: 30px;
          text-transform: uppercase;
          height: 39px;
          width: fit-content;
          padding-right: auto;
          margin-bottom: 17px;
          @media (max-width: 993px) {
            font-size: 20px;
            letter-spacing: 1.25px;
            height: auto;
            // flex-wrap: wrap;
          }
          .ruler {
            width: auto;
            height: 2px;
            background: #ffffff;
            box-shadow: 0 0 8px 2px rgba(255, 255, 255, 0.8);
            margin-top: 12px;
            @media (max-width: 993px) {
              display: none;
            }
          }
        }
      }
      .overlay {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 650px;
        width: 100%;
        background-image: -webkit-gradient(
          linear,
          left bottom,
          left top,
          color-stop(28%, rgba(0, 0, 0, 0.84)),
          color-stop(70%, rgba(0, 0, 0, 0))
        );
        background-image: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.84) 28%,
          rgba(0, 0, 0, 0) 70%
        );
        z-index: 2;
      }
    }
  }
  .desktop {
    @media (max-width: $lg) {
      display: none;
    }
  }
  .mobile {
    position: relative;
    @media (min-width: 993px) {
      display: none;
    }
  }
}
</style>
<style lang="scss">
/* Bootstrap Overrides */
#BrandingSignage {
  #sectionCarousel {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
    @media (max-width: 993px) {
      height: auto;
    }
    .carousel-inner {
      @media (max-width: 993px) {
        height: calc((100vw / 16) * 9 + 220px);
      }
    }
    .carousel-control-next,
    .carousel-control-prev {
      width: 70px;
      height: 70px;
      top: 50%;
      background-image: linear-gradient(180deg, #525252 0%, #1f1f1f 100%);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 0 6px 6px 0;
      opacity: 1;
      z-index: 2;
      @media (max-width: 993px) {
        display: none;
      }
    }
    .carousel-control-next {
      right: 0;
      transform: scaleX(-1);
    }
    .carousel-control-prev {
      left: 268px;
    }

    .carousel-control-next-icon,
    .carousel-control-prev-icon {
      background-image: url("~@/assets/icons/Carousel/Chevron-right-icon.svg");
      width: 28.3px;
      height: 28.3px;
    }
    .carousel-control-next-icon {
      transform: scaleX(-1);
    }
    .carousel-control-prev-icon {
      transform: scaleX(-1);
    }

    .carousel-indicators {
      background-image: linear-gradient(180deg, #525252 0%, #1f1f1f 100%);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      border-radius: 6px;
      border-radius: 6px;
      width: auto;
      max-width: 40%;
      min-width: 10%;
      height: 34px;
      padding: 14px 23px 16px 23px;
      bottom: 10px;
      left: calc(40% + 268px);
      right: auto;
      transform: translate(-50%);
      z-index: 2;
      @media (max-width: 993px) {
        left: 50%;
        bottom: 220px;
        height: 16px;
        border-radius: 8px;
        padding: 6px 13px 7px 13px;
      }

      li {
        width: 20px;
        height: 4px;
        background: #9d9d9d;
        box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
        border: 0;
        border-radius: 2px;
        border-radius: 2px;
        @media (max-width: 993px) {
          height: 3px;
          width: 12px;
        }
      }
      .active {
        opacity: 0.8;
        background: #ffffff;
        box-shadow: 0 0 8px 0 rgba(255, 255, 255, 0.5);
      }
    }
  }
}
</style>