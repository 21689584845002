var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"BrandingSignage"}},[_c('div',{staticClass:"menu-underlay"}),_c('b-carousel',{attrs:{"id":"sectionCarousel","interval":0,"indicators":"","controls":""}},[_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"carouselBg",style:({
            backgroundImage:
              'url(' +
              require(("@/assets/images/Carousel/BrandingSignage/" + _vm.bsImg1)) +
              ')',
          })},[_c('div',{staticClass:"carouselImgWrapper"},[_c('img',{staticClass:"carouselImg",attrs:{"src":require(("@/assets/images/Carousel/BrandingSignage/" + _vm.bsImg1))}}),(_vm.clientLogo)?_c('div',{staticClass:"clientLogoWrapper slide1-01"},[_c('img',{staticClass:"clientLogo",attrs:{"src":_vm.clientLogo}})]):_vm._e(),(_vm.clientLogo)?_c('div',{staticClass:"clientLogoWrapper slide1-02"},[_c('img',{staticClass:"clientLogo",attrs:{"src":_vm.clientLogo}})]):_vm._e()])]),_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"caption"},[_c('h1',[_vm._v(" North Video Boards "),_c('div',{staticClass:"ruler"})])])]},proxy:true}])}),_c('b-carousel-slide',{scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('div',{staticClass:"carouselBg",style:({
            backgroundImage:
              'url(' +
              require(("@/assets/images/Carousel/BrandingSignage/" + _vm.bsImg3)) +
              ')',
          })},[_c('div',{staticClass:"carouselImgWrapper"},[_c('img',{staticClass:"carouselImg",attrs:{"src":require(("@/assets/images/Carousel/BrandingSignage/" + _vm.bsImg3))}}),(_vm.clientLogo)?_c('div',{staticClass:"clientLogoWrapper slide3"},[_c('img',{staticClass:"clientLogo",attrs:{"src":_vm.clientLogo}})]):_vm._e()])]),_c('div',{staticClass:"overlay"}),_c('div',{staticClass:"caption"},[_c('h1',[_vm._v(" South Video Board "),_c('div',{staticClass:"ruler"})])])]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }